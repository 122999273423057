import { useTranslator } from '@translator-app/react';
import { ServerNotificationsBell } from '~/app/notification/server-notifications';
import Avatar from '~/components/avatar';
import BreadCrumb from '~/components/breadcrumb';
import { useApplicationContext } from '~/contexts/application-context';
import { useUserContext } from '~/contexts/user-context';
import useClickOutside from '~/hooks/use-click-outside';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';

import { DocumentationButton } from './documentation-button';

export type Props = {
  onHideSidebar: () => any;
  sidebarIsHidden: boolean;
};

function UserDropDown() {
  let router = useRouter();
  let { translate } = useTranslator();

  let links = [
    {
      label: translate('userDropdown.preferences'),
      action: () => {
        router.push('/user/preferences');
      },
    },
    {
      label: translate('userDropdown.changePassword'),
      action: () => {
        router.push('/user/change-password');
      },
    },
    {
      label: translate('userDropdown.logout'),
      action: () => {
        window.location.href = '/api/auth/logout';
      },
    },
  ];

  return (
    <div className="absolute rounded border right-0	z-50 bg-white shadow">
      {links.map((l) => {
        return (
          <div
            className="whitespace-nowrap py-2 px-4 cursor-pointer text-gray-800 hover:bg-gray-200"
            onClick={() => {
              if (l.action) {
                l.action();
              }
            }}
          >
            {l.label}
          </div>
        );
      })}
    </div>
  );
}

export const PageHeader: React.FC = () => {
  let router = useRouter();
  let { organisationId, shopId } = router.query;
  let { currentUser } = useUserContext();
  let [showDropdown, setShowDropdown] = React.useState(false);
  let hideDropdownCallback = useCallback(() => setShowDropdown(false), [setShowDropdown]);
  let dropdownRef = useClickOutside(hideDropdownCallback);
  let { translate, language } = useTranslator();
  let { organisation, shop } = useApplicationContext();

  let initials = currentUser.name[0];

  let breadcrumbs = React.useMemo(() => {
    let crumbs = [];

    crumbs.push({
      title: translate('pageHeader.organisationSelection'),
      href: '/',
    });

    if (organisationId) {
      crumbs.push({
        title: organisation ? organisation.name : translate('pageHeader.shopSelection'),
        href: '/[organisationId]',
        as: `/${organisationId}`,
      });
    }

    if (shopId && shop) {
      crumbs.push({
        title: shop.name,
      });
    }

    return crumbs;
  }, [organisationId, shopId, organisation, shop, language]);

  return (
    <div className="flex flex-wrap w-full justify-between items-center pl-4 pr-8 border-b-2">
      <div className="my-4">
        <BreadCrumb items={breadcrumbs}></BreadCrumb>
      </div>
      <div className="flex items-center my-4">
        <div>
          <DocumentationButton />
        </div>
        <div className="mx-4">
          <ServerNotificationsBell />
        </div>
        <div className="relative" ref={dropdownRef}>
          <div className="cursor-pointer" onClick={() => setShowDropdown(!showDropdown)}>
            <Avatar size={8} className="bg-blue-500" isCircle>
              {initials}
            </Avatar>
          </div>
          {showDropdown && <UserDropDown />}
        </div>
      </div>
    </div>
  );
};
