import { useTranslator } from '@translator-app/react';
import React from 'react';

export const DocumentationButton: React.FC = () => {
  let { translate, language } = useTranslator();

  return (
    <a
      href={`https://www.docs.viresmo.com/${language}`}
      className="text-gray-700 px-2 py-1 border-2 hover:border-gray-800 rounded"
    >
      {translate('documentation')}
    </a>
  );
};
