import { useTranslator } from '@translator-app/react';
import Spinner from '~/components/spinner';
import { useNotificationContext } from '~/contexts/notification-context';
import { useServerNotificationProvider } from '~/contexts/server-notifications-context';
import {
  useClearNotificationsMutation,
  useDeleteNotificationMutation,
  useNotificationsQuery,
} from '~/generated/graphql';
import useClickOutside from '~/hooks/use-click-outside';
import useDebouncedFlag from '~/hooks/use-debounced-flag';
import BellIcon from '~/icons/bell.svg';
import TrashIcon from '~/icons/trash.svg';
import { getErrorMessageFromError } from '~/utils/error-message';
import classNames from 'classnames';
import React from 'react';

import { captureException } from '../../pages/_app';

interface NotificationsDropdownProps {
  showDropdown: boolean;
}

const NotificationsDropdownContent: React.FC<NotificationsDropdownProps> = (props) => {
  const { showDropdown } = props;
  let { lastNotificationId } = useServerNotificationProvider();
  let { emitNotification } = useNotificationContext();
  const [isLoading, setIsLoading] = React.useState(false);
  const [{ data, fetching }, refetchNotifications] = useNotificationsQuery({
    pause: !showDropdown,
    requestPolicy: 'cache-and-network',
    variables: {
      pagination: {
        skip: 0,
        limit: 20,
      },
    },
  });
  const [, clearNotifications] = useClearNotificationsMutation();
  const [, deleteNotification] = useDeleteNotificationMutation();
  const { translate } = useTranslator();

  React.useEffect(() => {
    if (showDropdown) {
      refetchNotifications();
    }
  }, [showDropdown, lastNotificationId]);

  const handleClearNotifications = async () => {
    setIsLoading(true);

    try {
      const res = await clearNotifications({});
      if (res.error) {
        throw res.error;
      }
    } catch (err) {
      emitNotification.error({
        title: translate('errors.serverError'),
        content: getErrorMessageFromError(err),
      });

      captureException(err);
    }

    setIsLoading(false);
  };

  const handleDeleteNotification = async (id: string) => {
    setIsLoading(true);

    try {
      if (!id) {
        throw new Error('id is required to delete a notification');
      }

      const res = await deleteNotification({
        ids: [id],
      });
      if (res.error) {
        throw res.error;
      }
    } catch (err) {
      emitNotification.error({
        title: translate('errors.serverError'),
        content: getErrorMessageFromError(err),
      });

      captureException(err);
    }

    setIsLoading(false);
  };

  if (!showDropdown) {
    return null;
  }

  if ((!data && fetching) || isLoading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Spinner size={10} color="gray-400" />
      </div>
    );
  }

  let notifications = data?.notifications?.docs || [];
  if (notifications.length) {
    return (
      <div className="h-full w-full">
        <div
          className="py-2 flex justify-center items-center cursor-pointer text-blue-600 bg-gray-100 hover:underline hover:bg-blue-100 border-b-2"
          onClick={handleClearNotifications}
        >
          Clear notifications
        </div>

        <div>
          {notifications.map((notification) => {
            return (
              <div className="border-b-2 flex" key={notification._id}>
                <div
                  className={classNames('p-4 flex-1', {
                    'hover:bg-gray-200 cursor-pointer': !!notification.link,
                  })}
                  onClick={() => {
                    if (notification.link) {
                      window.location.href = notification.link;
                    }
                  }}
                >
                  {notification.message}
                </div>
                <div
                  className="cursor-pointer p-2 flex justify-center items-center text-gray-400 hover:bg-gray-200 hover:text-red-600"
                  onClick={() => {
                    handleDeleteNotification(notification._id);
                  }}
                >
                  <TrashIcon className="w-6 h-6" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else {
    return (
      <div className="w-full h-full flex flex-col justify-center items-center">
        <div className="w-36 h-36">
          <img src="/illustrations/chilling.svg" />
        </div>
        <div className="my-4 font-medium text-lg">{translate('serverNotifications.noNotifications')}</div>
      </div>
    );
  }
};

export const ServerNotificationsBell = (props) => {
  let { notificationCount } = useServerNotificationProvider();
  const [showDropdown, toggleShowDropdown, setShowDropdown] = useDebouncedFlag(false);
  const dropdownRef = useClickOutside(
    React.useCallback(() => {
      setShowDropdown(false);
    }, [setShowDropdown])
  );

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className="rounded-full w-8 h-8 flex justify-center items-center cursor-pointer hover:bg-blue-200 relative"
        onClick={toggleShowDropdown}
      >
        <BellIcon className="w-5 h-5" />
        <div
          className={classNames(
            'absolute bg-red-600 text-white font-medium rounded-full h-6 flex justify-center items-center',
            {
              hidden: notificationCount <= 0,
            }
          )}
          style={{
            top: -5,
            right: -5,
            minWidth: '1.5rem',
          }}
        >
          {notificationCount}
        </div>
      </div>
      {showDropdown && (
        <div className="absolute top-10 z-50 right-0 shadow rounded bg-white w-96 h-96 overflow-y-auto">
          <NotificationsDropdownContent showDropdown={showDropdown} />
        </div>
      )}
    </div>
  );
};
