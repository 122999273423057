import NotificationContainer from '~/app/notification/notifications-container';
import SEO from '~/components/seo';
import React from 'react';

import { PageHeader } from './page-header';

export type Props = {
  children: React.ReactNode;
  title?: string;
  headingButtons?: React.ReactNode | Array<React.ReactNode>;
};

export const PageWrapper: React.FC<Props> = (props) => {
  let { children, title } = props;

  return (
    <>
      <SEO title={title} />
      <NotificationContainer />

      <div className="w-full h-screen">
        <PageHeader />
        <div className="w-full h-full">{children}</div>
      </div>
    </>
  );
};
