import Notification from '~/components/notification';
import { useNotificationContext } from '~/contexts/notification-context';
import React from 'react';

export type Props = {};

export default function NotificationContainer(props: Props) {
  let { notifications } = useNotificationContext();

  return (
    <div
      className="fixed flex justify-end"
      style={{
        bottom: 12,
        right: 12,
        zIndex: 999,
      }}
    >
      {notifications.map((n, i) => {
        return (
          <Notification
            title={n.title}
            content={n.content}
            timeout={n.timeout}
            type={n.type}
            index={i}
            depth={notifications.length - i}
            id={n.id}
            key={n.id}
          ></Notification>
        );
      })}
    </div>
  );
}
