import Head from 'next/head';
import React from 'react';

export type Props = {
  title?: string;
};

export default function SEO(props: Props) {
  let { title } = props;

  return (
    <Head>
      <title>Viresmo{title && ` - ${title}`}</title>
    </Head>
  );
}
