export function getErrorMessageFromError(e: any): string {
  let errorMessage = e.message;
  if (Array.isArray(e.graphQLErrors) && e.graphQLErrors.length) {
    errorMessage = e.graphQLErrors.map((e) => e.message).join('\n');
  }

  if (e.networkError && e.networkError.result && e.networkError.result.errors.length) {
    errorMessage = e.networkError.result.errors.map((e) => e.message).join('\n');
  }

  return errorMessage;
}
