import { useNotificationContext } from '~/contexts/notification-context';
import useTimeout from '~/hooks/use-timeout';
import CrossIcon from '~/icons/x.svg';
import classNames from 'classnames';
import React from 'react';

export type Props = {
  id: string;
  type: string;
  title: React.ReactNode;
  content?: React.ReactNode;
  timeout: number;
  depth: number;
  index: number;
};

export default function Notification(props: Props) {
  let { title, content, type, depth, id, index, timeout } = props;

  let { deleteNotification } = useNotificationContext();
  let [executeTimeout, cancelTimeout] = useTimeout({
    autoStart: true,
    timeout,
    callback: () => {
      deleteNotification(id);
    },
  });

  return (
    <div
      className={classNames('px-4 py-2 shadow rounded w-64 border absolute', {
        'bg-red-500 text-white': type === 'error',
        'bg-green-500 text-white': type === 'success',
        'bg-yellow-500 text-white': type === 'warning',
        'bg-white text-gray-800': type === 'info',
      })}
      style={{
        zIndex: 1000 + index * 5,
        bottom: 10 + depth * 2,
      }}
      onMouseLeave={() => {
        executeTimeout();
      }}
      onMouseEnter={() => {
        cancelTimeout();
      }}
    >
      <div className="flex justify-between items-center">
        <div className="font-bold">{title}</div>
        <div className="cursor-pointer" onClick={() => deleteNotification(id)}>
          <CrossIcon className="w-4 h-4" />
        </div>
      </div>
      <div>{content}</div>
    </div>
  );
}
