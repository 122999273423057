import { Button } from '~/components/button';
import React from 'react';

export interface BreadCrumbItemProps {
  title: string;
  href?: string;
  as?: string;
}

export interface BreadCrumbProps {
  items: Array<BreadCrumbItemProps>;
}

const BreadCrumbItem: React.FC<BreadCrumbItemProps> = (props) => {
  let { title, href, as } = props;

  if (href) {
    return (
      <Button href={href} as={as} appearance="subtle">
        {title}
      </Button>
    );
  } else {
    return <div className="block rounded border border-transparent px-4 py-1">{title}</div>;
  }
};

const BreadCrumb: React.FC<BreadCrumbProps> = (props) => {
  let { items } = props;

  return (
    <div className="md:flex flex-wrap items-center">
      {items.map((item, i) => {
        return (
          <div key={i} className="flex items-center">
            <BreadCrumbItem {...item} />
            {i < items.length - 1 && <div className="mx-2">/</div>}
          </div>
        );
      })}
    </div>
  );
};

export default BreadCrumb;
