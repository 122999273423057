import React from 'react';

export default function useDebouncedFlag(
  initialFlag: boolean = false,
  ms: number = 5
): [boolean, () => void, (newFlag: boolean) => void] {
  let [flag, setFlag] = React.useState(initialFlag);
  let [timeoutReference, setTimeoutReference] = React.useState<{ reference?: NodeJS.Timeout; type?: 'switch' | 'set' }>(
    {}
  );

  const requestFlagUpdate = React.useCallback(() => {
    if (timeoutReference.type !== 'set') {
      if (timeoutReference.reference) {
        clearTimeout(timeoutReference.reference);
      }

      let t = setTimeout(() => {
        setFlag(!flag);
        setTimeoutReference({});
      }, ms);

      setTimeoutReference({ reference: t, type: 'switch' });
    }
  }, [timeoutReference, setTimeoutReference, flag, setFlag]);

  const requestFlagSet = React.useCallback(
    (newFlagValue: boolean) => {
      if (timeoutReference.type !== 'set') {
        if (timeoutReference.reference) {
          clearTimeout(timeoutReference.reference);
        }

        let t = setTimeout(() => {
          setFlag(newFlagValue);
          setTimeoutReference({});
        }, ms);

        setTimeoutReference({ reference: t, type: 'switch' });
      }
    },
    [timeoutReference, setTimeoutReference, flag, setFlag]
  );

  return [flag, requestFlagUpdate, requestFlagSet];
}
