import classNames from 'classnames';
import React from 'react';

export type AvatarProps = {
  children: string;
  size?: 8 | 10 | 16;
  isCircle?: boolean;
  className?: string;
};

export default function Avatar(props: AvatarProps) {
  let { children, size, isCircle, className } = props;

  return (
    <div
      className={classNames('inline-flex items-center justify-center overflow-hidden border text-white', className, {
        'w-8 h-8 text-sm': size === 8,
        'w-10 h-10 text-lg': size === 10,
        'w-16 h-16 text-2xl': size === 16,
        'rounded-full': isCircle,
        rounded: !isCircle,
      })}
    >
      {children.toUpperCase()}
    </div>
  );
}
