import React from 'react';

export function useRefBox(value) {
  const ref = React.useRef(value);

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref;
}

export function useCancelableTimeout({ callback, timeout, autoStart = true }) {
  let timer = React.useRef();
  let savedCallback = useRefBox(callback);
  let savedTimeout = useRefBox(timeout);

  let execute = React.useCallback(() => {
    // @ts-ignore
    timer.current = setTimeout(() => {
      savedCallback.current();
    }, savedTimeout.current);
  }, [savedTimeout, savedCallback]);

  let cancel = React.useCallback(() => {
    clearTimeout(timer.current);
  }, []);

  React.useEffect(() => {
    if (autoStart) {
      execute();
    }

    return cancel;
  }, [cancel, autoStart]);

  return React.useMemo(() => [execute, cancel], [execute, cancel]);
}

export default useCancelableTimeout;
